import { groq } from "next-sanity";
import {
  site,
  pageData,
  quizData,
  guideData,
  marketData,
  imageFields,
  galleryPhoto,
  modulesBlock,
  spaceAttribute,
  designStyleData,
} from "./queries";

export const GET_ALL_STATIC_SLUGS_QUERY = groq`
  {
    "slugs": *[defined(slug.current) && _type == "page"][].slug.current,
  }
`;

export const GET_ALL_FLOW_SLUGS_QUERY = groq`
  {
    "slugs": *[defined(slug.current) && _type == "flow" && !(_id in path("drafts.**"))][].slug.current,
  }
`;

export const GET_PAGE_DATA_BY_DYNAMIC_SLUG_QUERY = groq`
  {
    "page": *[_type == "dynamicPage" && slug.current == $slug][0] {
      slug,
      title,
      seo,
      sections[] {
        sectionKey,
        ${modulesBlock}
      }
    },
    ${site}
  }`;

export const GET_ALL_QUIZ_SLUGS_QUERY = groq`
  {
    "slugs": *[defined(slug.current) && _type == "quiz" && !(_id in path("drafts.**"))][].slug.current,
  }
`;

export const GET_QUIZ_DATA_BY_SLUG_QUERY = groq`
  {
    "quiz": *[_type == "quiz" && slug.current == $slug && !(_id in path("drafts.**"))][0] {
      ${quizData}
    },
    ${site}
  }`;

export const GET_ALL_STORY_SLUGS_QUERY = groq`
  {
    "slugs": *[defined(slug.current) && _type == "story"][].slug.current,
  }
`;

export const GET_STORY_DATA_BY_SLUG_QUERY = groq`
  {
    "story": *[_type == "story" && slug.current == $slug][0] {
      ${pageData}
    },
    ${site}
  }`;

export const GET_ALL_MARKET_SLUGS_QUERY = groq`
  {
    "slugs": *[defined(slug.current) && _type == "market"][].slug.current,
  }
`;

export const GET_MARKET_DATA_BY_SLUG_QUERY = groq`
  {
    "market": *[_type == "market" && slug.current == $slug][0] {
      ${marketData}
    },
    ${site}
  }`;

const ALL_GUIDES = `_type == "guide" && defined(slug.current) && defined(mainImage) && defined(category->slug.current)`;

export const GET_ALL_GUIDE_SLUGS_QUERY = groq`
  {
    "guides": *[${ALL_GUIDES}] {
      "slug": slug.current,
      category -> { "slug": slug.current }
    }
  }
`;

export const GET_ALL_GUIDES_LIST_QUERY = groq`
  {
    "guides": *[${ALL_GUIDES}] {
      ${guideData}
    },
    ${site}
  }
`;

export const GET_GUIDE_DATA_BY_SLUG_QUERY = groq`
  {
    "guide": *[_type == "guide" && slug.current == $slug][0] {
      ${guideData}
    },
    ${site}
  }`;

export const GET_ALL_GUIDE_TAG_SLUGS_QUERY = groq`
  {
    "slugs": *[defined(slug.current) && _type == "guideTag"][].slug.current,
  }
`;

export const GET_ALL_GUIDES_BY_TAG_QUERY = groq`
  {
    "guides": *[${ALL_GUIDES} && $slug in tags[]->slug.current] {
      ${guideData}
    },
    ${site}
  }
`;

export const GET_DESIGN_STYLE_DATA_BY_SLUG_QUERY = groq`
  {
    "designStyle": *[_type == "designStyles" && slug.current == $slug][0] {
      ${designStyleData}
    },
    ${site}
  }`;

export const GET_ALL_DESIGN_STYLE_SLUGS_QUERY = groq`
  {
    "slugs": *[defined(slug.current) && _type == "designStyles"][].slug.current,
  }
`;

export const GET_ALL_PHOTOS_QUERY = groq`
  {
    "pictures": *[_type == "picture"] {
      _id,
      image { ${imageFields} }
    }
  }
`;

const GALLERY_PICTURES = `_type == "picture" && pictureType.pictureType == "afterProject" && !(_id in path("drafts.**"))`;

export const GET_GALLERY_PHOTOS = groq`
  {
    "pictures": *[${GALLERY_PICTURES} && hideOnGallery != true] | order(orderRank) {
      ${galleryPhoto}
    }
  }
`;

export const GET_GALLERY_PHOTOS_PROJECT_SLUGS = groq`
  {
    "pictures": *[${GALLERY_PICTURES} && hideOnGallery != true] | order(orderRank) {
      _id,
      project -> { 
        "slug": slug.current
      }
    }
  }
`;

export const GET_GALLERY_PAGE = groq`
  {
    "galleryMenu": *[_type == "galleryMenu"][0] {
      navItems[] {
        _key,
        icon,
        label,
        value,
        tags[]-> {
          label,
          value,
          internalSpace {
            id
          }
        },
        seo,
      }
    },
  }
`;

export const GET_SPACE_ATTRIBUTES = groq`
  {
    "spaceAttributes": *[_type == "attributes" && attributeType == "space"] {
      ${spaceAttribute}
    }
  }
`;

export const GET_PROJECT_PICTURES = groq`
{
  "pictures": *[${GALLERY_PICTURES} && $id == project._ref] | order(orderRank) {
    _id,
    image,
    title,
    isFeatured
  }
}
`;

export const GET_QUIZZES = groq`
{
  "quizzes": *[_type == "quiz" && slug.current == $slug && !(_id in path("drafts.**"))][0] {
    ${quizData}
  },
  ${site}
}
`;

export const GET_SPACE_QUESTIONS = groq`
{
  "quizzes": *[_type == "quiz" && !(_id in path("drafts.**")) && isSpaceQuestion == true] {
    ...,
    questions[] {
      ...,
      _type == "quizQuestionImage" => {
        questionAnswers[] -> {
          ...,
          title,
          image,
        }
      }
    }
  }
}
`;

export const GET_NEXT_QUESTIONS = groq`
{
  "quizzes": *[_type == "quizQuestionStandard"] {
    ...,
  }
}
`;

const getSitemapPageSlugs = (page) =>
  `*[_type == "${page}" && defined(slug.current) && !(_id in path("drafts.**")) && seo.shouldIndex == true].slug.current`;

export const GET_SITEMAP_PAGES = groq`
{
  "pages": ${getSitemapPageSlugs("page")},
  "stories": ${getSitemapPageSlugs("story")},
  "markets": ${getSitemapPageSlugs("market")},
  "designStyles": ${getSitemapPageSlugs("designStyles")},
  "dynamicPages": ${getSitemapPageSlugs("dynamicPage")},
}
`;

export const GET_DESIGN_STYLES = groq`
{
  "designStyles": *[_type == "designStyles" && !(_id in path("drafts.**"))] {
    ...,
  }
}
`;

export const GET_REVIEWS = groq`
{
  "reviews": *[_type == "reviews" && !(_id in path("drafts.**"))] {
    ...,
    featuredImage -> { _id, image },
    project -> {
      ...,
      _id,
      address, 
      client -> { title, image },
      rooms,
      beds,
      baths,
      spaces[] { spaceName, description, sqft, measurementsWidth, measurementsLength, tags[] { label, value } }
    },
  }
}
`;

export const GET_REVIEWS_BY_TYPE = groq`
{
  "reviews": *[_type == "reviews" && !(_id in path("drafts.**")) && reviewType == $reviewType] {
    ...,
    featuredImage -> { _id, image },
    project -> {
      ...,
      _id,
      address, 
      client -> { title, image },
      rooms,
      beds,
      baths,
      spaces[] { spaceName, description, sqft, measurementsWidth, measurementsLength, tags[] { label, value } }
    },
  }
}
`;

export const getPageDataByStaticSlug = (showDrafts = false) => groq`
  {
    "page": *[(_type == "page" || _type == "flow") && slug.current == $slug ${
      !showDrafts ? '&& !(_id in path("drafts.**"))' : ""
    }][0] {
      ${pageData}
    },
    ${site}
  }`;

export const getQuizQuestionImages = (spaces) => {
  const filter = spaces.map((id) => `"${id}" in spaces[]._key`).join(" || ");
  const finalFilter = filter ? `&& (${filter})` : "";
  return groq`
    {
      "images": *[${GALLERY_PICTURES} ${finalFilter}] | order(orderRank) {
        ${galleryPhoto}
      }
    }
  `;
};

const GALLERY_INSPIRATION_PICTURES = `_type == "picture" && pictureType.pictureType == "inspiration" && !(_id in path("drafts.**"))`;

export const getQuizQuestionInspirationImages = () => {
  return groq`
    {
      "images": *[${GALLERY_INSPIRATION_PICTURES}] | order(orderRank) {
        ${galleryPhoto}
      }
    }
  `;
};
