const homeID = `*[_type=="generalSettings"][0].home->_id`;

const page = `
  "type": _type,
  "slug": slug.current,
  "isHome": _id == ${homeID},
`;

export const imageMeta = `
  "alt": coalesce(alt, asset->altText),
  asset,
  crop,
  customRatio,
  hotspot,
  "id": asset->assetId,
  "type": asset->mimeType,
  "aspectRatio": asset->metadata.dimensions.aspectRatio,
  "lqip": asset->metadata.lqip
`;

export const imageFields = `
  "alt": coalesce(alt, asset->alt),
  "title": coalesce(title, asset->title, alt, asset->alt),
  "originalFilename": asset->originalFilename,
  asset -> { _id },
`;

export const ptContent = `
  ...,
  markDefs[]{
    ...,
    _type == "link" => {
      "url": @.url,
      "linkType": @.linkType,
      "isInternal": @.isInternal,
      "styles": @.styles{style, isLarge, isBlock},
      "page":@.page->{
        ${page}
      }
    }
  },
  _type == "photo" => {
    ${imageMeta}
  }
`;

export const blocks = `
  _type == 'freeform' => {
    _type,
    _key,
    content[]{
      ${ptContent}
    },
    textAlign,
    maxWidth
  },
  _type == 'heroSimple' => {
    _type,
    _key,
    title,
    content[]{
      ${ptContent}
    },
    textAlign,
    maxWidth
  }
  `;

export const blockMenu = `
 items[] {
   label,
   target { ..., page -> { ${page} } },
 }
`;

export const galleryPhoto = `
  _id,
  title,
  image { ${imageFields} },
  isFeatured,
  cloudinaryPath,
  pictureType { pictureType },
  project -> { 
    _id, 
    title, 
    "slug": slug.current, 
    address, 
    projectManager -> { title, image },
    client -> { title, image },
    startDate,
    finishDate,
    rooms,
    sqft,
    beds,
    baths,
    spaces[] { _key, spaceName, description, sqft, measurementsWidth, measurementsLength, tags[] { label, value } }
  },  
  spaces[] { spaceName, _key },
  tags[] {
    value,
    label
  }
`;

export const modules = `
  ...,
  _type,
  _key,
  content[] { ${ptContent} },
  bgType,
  photos {
    ...,
    mobilePhoto { ${imageMeta} },
    desktopPhoto { ${imageMeta} }
  },
  faqGroup -> { title, faqs },
  blocks[]{
    ${blocks}
  },
  hero { istTop },
  video { id, title, video { asset -> { ... } } },
  featuredPhoto -> { _id, image },
  link { ..., page -> { ${page} } },
  _type == "testimonials" => {
    _type,
    cards[] -> {
      ...
    }
  },   
  _type == "heroGrid" => { ... , mobileContent[] -> {content[0] } },
  _type == "gallery" => { _type, pictures[] -> { ${galleryPhoto} } },
  _type == "horizontalMasonry" => { ..., pictures[] -> { ${galleryPhoto} } },
  _type == "heroSpaceSelection" => { 
    ...,
    modules[] {
       ...,
       faqGroup -> { title, faqs }
    } 
  },
  _type == "video" => {
    ...,
    video {
      asset -> {
        ...,
      }
    }
  },
  _type == "showcase" => {
    ...,
    project -> { "slug": slug.current, address { city, state } },
    featuredImage -> { _id, image { ${imageFields} } }
  }
`;

export const modulesBlock = `
  modules[] {
    _type == "carousel" => { ...@ -> {..., cards[] -> {
      link,mainImage,title,subTitle,hasOverlay,
    }} },
    defined(_ref) => { ...@->content[0] {
      ${modules}   
    }},
    !defined(_ref) => {
      ${modules}   
    }        
  }
`;

export const pageData = `
  ...,
  "id": _id,
  slug,
  title,
  optimizeExperimentId,
  optimizeExperimentVariant,
  seo,
  project -> { _id },
  hasTransparentHeader,
  slides[]  {
    ...,
    bigMedia {
      ...,
      video {
        asset -> {
          ...,
        }
      }
    },
    modal[] {
      ...,
      modalSlide[] {
        ...,
        video {
          asset -> {
            ...,
          }
        }
      }
    }
  },
  ${modulesBlock}
`;

export const marketData = `
  ...,
  "slug": slug.current,
  faq -> { ... },
  mapImage { ${imageFields} }, 
  review { ..., reviewImage { ${imageFields} } },
  featuredGuide -> { title, "slug": slug.current, category -> { "slug": slug.current } },
  subMarket[] -> { _id, market, "slug": slug.current },
  intro { ..., picture { ${imageFields} }, link { ..., page -> { ..., "slug": slug.current } } },
  relatedContent[] -> { ..., market -> { ..., "slug": slug.current }, "slug": slug.current }
`;

export const guideData = `
  ...,
  tags[] -> { _id, title, "slug": slug.current },
  category -> { title, "slug": slug.current },
  mainImage { ${imageFields} },
  "slug": slug.current,
`;

export const designStyleData = `
  ...,
  "slug": slug.current,
`;

export const quizData = `
  seo,
  questions[] {
    ...,
    _type == "quizQuestionImage" => {
      questionAnswers[] -> {
        ...,
        title,
        image,
      }
    },
    _type == "quizQuestionContactSlide" => {
      ...,
      redirectLink -> { ${page} }
    }
  }
`;

export const site = `
  "site" : {
    "promoBar": *[_type == "promoBar" && !(_id in path("drafts.**"))][0] {
      ...
    },
    "header": select(
      $slug != "dashboard" => *[_type == "navigation" && slug.current == "top" && !(_id in path("drafts.**"))].items[] {
        label,
        target { title, url, isInternal, page -> { ${page} } }
      },
      $slug == "dashboard" => *[_type == "navigation" && slug.current == "clientPrimary" && !(_id in path("drafts.**"))].items[] {
        label,
        target { title, url, isInternal, page -> { ${page} } }
      },
    ),
    "footer": *[_type == "footerSettings"][0] {
      ...,
      blockMenu1-> { ${blockMenu} },
      blockMenu2-> { ${blockMenu} },
      blockMenu3-> { ${blockMenu} },
      blockMenu4-> { ${blockMenu} },
      blockMenu5-> { ${blockMenu} }
    }
  }
`;

export const spaceAttribute = `
  _id,
  internalSpace {
    id,
    spaceName
  }
`;
